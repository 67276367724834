// 
// custom.scss
//

@import "../../config/evenio/custom-variables";

.topbar-nav .side-nav-link i {
  margin: 0 .5rem 0 0;
  font-size: 1.5rem;
  vertical-align: middle;
}

.rbt-aux {
  position: absolute;
  top: 8px;
  right: 8px;
}

.btn-icon {
  padding: 0 0.3em;
}

.btn-icon i {
  font-size: 1.5em;
}

.preloader {
  z-index: 999;
}

.table-icons {
  width: 1%;
  white-space: nowrap;
}

.react-bootstrap-table .table td {
  vertical-align: middle;
}

// TypeAhead
.text-danger .rbt-input {
  border-color: #ff5b5b !important;
}

// Custom File Input
.custom-file-label::after {
  font-family: dripicons-v2;
  content: "\e041" !important;
}

// WYSIWYG
.rdw-editor-main {
  padding: 1px 5px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  min-height: 300px;
}

.rdw-editor-toolbar {
  padding: 0 !important;
  border: none !important;
}

.rdw-option-wrapper {
  border: 1px solid #dee2e6 !important;
  padding: 11px 5px !important;
  border-radius: 0.25rem !important;
  margin: 0 2px !important;
  background: #ffffff !important;
  min-width: 30px !important;
  min-height: 30px !important;
}

.rdw-option-wrapper:hover, .rdw-option-wrapper.rdw-option-active {
  box-shadow: none;
  background-color: #f3f3f3 !important;
}

.cardHeader {
  background-color: rgba(250, 92, 124, .2);
  color: #fa5c7c;
  padding: 5px 0;
  font-weight: 700;
  border-radius: .25rem .25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem;
}

.answers {
  display: inline-block;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
  border: 1px solid #dee2e6;
  vertical-align: top;
  margin-bottom: 24px;
  border-radius: 0.25rem;
}

.btn-light-outline {
  border-color: #6d6d6d;
}

/*
.answerRadioButton .custom-control-label {
  width: 100%;
}
*/
.answerRadioButton  {
  position: absolute;
  left: -15px;
  top: 50%;
  margin-top: -5px;
}

.removeAnswer {
  position: absolute;
  right: -20px;
  margin-top: -15px;
  color: #ff8e8e;
  font-size: 24px;
  cursor: pointer;
  top: 50%;
  z-index: 999;
}

.fullAnswer .removeAnswer {
  right: 10px;
}

.fillAnswer .removeAnswer {
  right: -12px;
  font-size: 20px;
}

.fillAnswer .answerRadioButton {
  margin-top: -12px;
  left: -10px;
}

.answerRadioGroup legend {
  font-size: 1.2rem;
  margin-bottom: .7rem;
}

.isDraggable, .isDraggableInner, .isPlayingBox {
  position: relative;
}

.isDraggable:before, .isDraggableInner:before {
  font-family: "dripicons-v2"!important;
  content: '\e020';
  position: absolute;
  top: 50%;
  margin-top: -11px;
  font-size: 15px;
  left: -8px;
  color: #b9b9b9;
}

.isDraggableInner img, .isPlayingBox img {
  max-width: 100%!important;
}

.isDraggableInner:before {
  left: 3px;
}

.tasks {
  width: 100%;
  padding-bottom: 50px;
}

.task-header .form-group {
  margin-bottom: 0;
}

.task-header .form-control {
  width: 90%;
  height: 30px;
  font-size: 0.9em;
}

.react-bootstrap-table > table > thead > tr > th .filter-label {
  display: block !important;
  margin-top: 5px;
}

.escape-window .participants {
  position: absolute;
  right: 1rem;
  font-size: 1.7rem;
  opacity: .3;
  top: 0.6rem;
}

.pointer {
  cursor: pointer;
}

.radius-4 {
  border-radius: 4px;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

.font-3rem {
  font-size: 3rem;
}

.font-4rem {
  font-size: 4rem;
}

.font-5rem {
  font-size: 5rem;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.image-selected {
  border: 2px solid $evenioPrimary!important;
}

.active.active-question {
  background-color: #ffb371  !important;
  border-color: #ffb371  !important;
}

.footer {
  background-color: #FFF;
}

.react-datepicker-popper {
  margin-top: 10px;
  z-index: 99;
}

.toast.top-right-toast {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99999;
}

.panic-messages {
  position: fixed;
  top: 140px;
  left: 10px;
  height: 80vh;
  overflow: hidden;
}

.ringing-bell {
  position: absolute;
  transition: translate(-50%, -50%)
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg)
  }

  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }

  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }

  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg)
  }

  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg)
  }

  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg)
  }

  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }

  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg)
  }

  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg)
  }

  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg)
  }

  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg)
  }

  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg)
  }

  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }

  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg)
  }

  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg)
  }

  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg)
  }

  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }

  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg)
  }

  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg)
  }

  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg)
  }

  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg)
  }
}

.ringing-bell,
.ringing-bell:hover {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial
}

.video-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.concept-number {
  position: absolute;
  left: -8px;
  line-height: 13px;
}

.rdw-image-modal {
  z-index: 1000;
}

.sweet-alert  {
  z-index: 9999999999!important;
}

.jigsaw-puzzle {
  width: 100%;
}

.jigsaw-puzzle__piece--solved {
  border-color: #ed7004 !important;
}

div.htmlParsed {
  margin-bottom: 20px;
}

.htmlParsedContainer .react-select__control {
  min-height: 28px !important;
}

.htmlParsedContainer .react-select--inline {
  margin: -1px 0 !important;
}